import React, {
  Suspense, useContext,
} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MainContext from "./context/main/mainContext";
import "./content/styles/App.css";
import history from "./utilities/history";
import Spinner from "./utilities/Spinner";
import Search from "./Search";
import StripeHome from "./StripeHome";
import AdminLogin from "./AdminLogin";
import Home from "./Home";
import Login from "./Login";
import SnackbarComponent from "./utilities/SnackbarComponent";

const App = () => {
  const { user, logout } = useContext(MainContext);

  let component;
  if (!user) {
    component = <><Route path="/" component={Login} /></>;
  } else {
    component = <>
      <Route path="/" exact component={Home} />
      <Route path="/stripe" exact component={StripeHome} />
      <Route path="/search" exact component={Search} />
      <Route path="/login/:id" component={AdminLogin} />
    </>;
  }

  return (
    <BrowserRouter history={history}>
      <SnackbarComponent />
      <Suspense fallback={<Spinner />}>
        <Switch>
          {component}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
