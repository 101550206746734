import React, { forwardRef, useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import mainContext from "../context/main/mainContext";

const SnackbarComponent = () => {
  const { snackbarData, snackbarAction } = useContext(mainContext);

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />
  ));

  return (
    <div className="snackbar" style={snackbarData?.isOpen || snackbarData?.message ? { opacity: 1 } : { opacity: 0 }}>
      <Snackbar
        open={snackbarData?.isOpen || snackbarData?.message}
        autoHideDuration={snackbarData?.duration}
        onClose={() => snackbarAction({
          message: "", severity: "", duration: 0, isOpen: false,
        })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        ClickAwayListenerProps={{ onClickAway: () => { } }}
      >
        <Alert severity={snackbarData?.severity}>
          {snackbarData?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
